import { useContext, useEffect, useState } from 'react';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import UserInfoContext from '../../contexts/UserInfoContext';
import _ from 'lodash';
import { getCustomerTypesList } from '../../api/utils/customer/type/getCustomerTypesList';
import { deleteCustomerType } from '../../api/utils/customer/type/setCustomerType';
import CustomerTypeList from './CustomerTypeList';
import CustomerTypeModal from './CustomerTypeModal';
import ConfirmModal from '../common/ConfirmModal';

const PER_PAGE_CHOICES = [25, 50, 100, 500];

const CustomerTypes = () => {
    const {
        app: { id: appId, name: appName },
        merchant: { id: merchantId, name: merchantName },
    } = useContext(UserInfoContext);

    const pageTitle = `Customer types (${appName ? appName : '-'} / ${
        merchantName ? merchantName : '-'
    })`;

    const [customerTypeLst, setCustomerTypeLst] = useState();

    const [sortField, setSortField] = useState('created_at');
    const [sortDirection, setSortDirection] = useState(-1);

    const [perPage, setPerPage] = useState(PER_PAGE_CHOICES[0]);
    const [totalRows, setTotalRows] = useState(0);

    const [loading, setLoading] = useState(false);

    const [showConfirmRemoveCustomerType, setShowConfirmRemoveCustomerType] =
        useState(false);
    const handleShowConfirmRemoveCustomerType = () =>
        setShowConfirmRemoveCustomerType(true);
    const handleCloseConfirmRemoveCustomerType = () =>
        setShowConfirmRemoveCustomerType(false);
    const [customerTypeToRemove, setCustomerTypeToRemove] = useState({});

    const [showCustomerTypePreview, setShowCustomerTypePreview] =
        useState(false);
    const handleShowCustomerTypePreview = () =>
        setShowCustomerTypePreview(true);
    const handleCloseCustomerTypePreview = () =>
        setShowCustomerTypePreview(false);
    const [currentCustomerType, setCurrentCustomerType] = useState();

    useEffect(() => {
        if (merchantId && appId) {
            fetchCustomerTypes();
        }
    }, [merchantId, appId, perPage, sortField, sortDirection]);

    const fetchCustomerTypes = async (page = 1) => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                setCustomerTypeLst(data.customer_types);
                setTotalRows(data.total);
                setLoading(false);
            } else {
                setCustomerTypeLst([]);
                setTotalRows(0);
                setLoading(false);
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
            setCustomerTypeLst([]);
            setTotalRows(0);
            setLoading(false);
        };

        setLoading(true);
        try {
            const res = await getCustomerTypesList(
                {
                    query_merchant: merchantId,
                    query_application: appId,
                    query_skip: (+page - 1) * perPage,
                    query_count: perPage,
                    query_sort_field: sortField,
                    query_sort_direction: sortDirection,
                },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.log(
                'Unexpected error while fetching customer types: ',
                error
            );
        }
    };

    const onDeleteClick = (id, name) => {
        setCustomerTypeToRemove({ id, name });
        handleShowConfirmRemoveCustomerType();
    };

    const deleteSelectedCustomerType = async () => {
        const resultHandler = (data) => {
            if (data && data.status === 'accept') {
                NotificationManager.success(
                    'Customer type removed',
                    'Success',
                    4000
                );
                handleCloseConfirmRemoveCustomerType();
                fetchCustomerTypes();
            }
        };

        const errorHandler = (error) => {
            NotificationManager.error(error.description, 'Error', 4000);
        };

        try {
            const res = await deleteCustomerType(
                { id: customerTypeToRemove.id },
                errorHandler
            );
            resultHandler(res);
        } catch (error) {
            console.error(
                'Unexpected error while removing customer type: ',
                error
            );
        }
    };

    let onRowClick = (row) => {
        setCurrentCustomerType(row);
        handleShowCustomerTypePreview();
    };
    const handlePageChange = (page) => {
        fetchCustomerTypes(page);
    };
    const handleRowsPerPage = (count) => {
        setPerPage(count);
    };

    const handleSort = (column, sortDirection) => {
        setSortField(column?.init_name || 'created_at');
        setSortDirection(sortDirection === 'asc' ? -1 : 1);
    };

    return (
        <div className="container-fluid">
            <NotificationContainer />
            <h2>{pageTitle}</h2>

            {merchantId ? (
                <Link
                    to={`/customer/type/create`}
                    className="btn btn-outline-primary mt-1 mb-3"
                >
                    <i className="bi-plus-square ml-1 mr-2 " /> Create customer
                    type
                </Link>
            ) : null}

            <CustomerTypeList
                customerTypes={customerTypeLst}
                loading={loading}
                onRowClicked={onRowClick}
                onDeleteClick={onDeleteClick}
                emptyText="The customer types list is empty"
                totalRows={totalRows}
                handlePageChange={handlePageChange}
                rowsCountPerPage={perPage}
                handleRowsPerPage={handleRowsPerPage}
                perPageChoices={PER_PAGE_CHOICES}
                handleSort={handleSort}
            />

            {currentCustomerType && (
                <CustomerTypeModal
                    customerTypeId={currentCustomerType._id}
                    show={showCustomerTypePreview}
                    onHide={handleCloseCustomerTypePreview}
                />
            )}

            <ConfirmModal
                title="Delete customer type"
                text="Are you sure you want to delete this customer type?"
                show={showConfirmRemoveCustomerType}
                onHide={handleCloseConfirmRemoveCustomerType}
                confirmAction={deleteSelectedCustomerType}
            />
        </div>
    );
};

export default CustomerTypes;
