import Honkio from '../../../Honkio';

const setCustomerType = (options = {}, errorHandler = () => {}) => {
    const { id, name, description, merchant, application, properties, action } =
        options;
    const params = {
        id,
        name,
        description,
        merchant,
        application,
        properties,
        action,
        version: 3,
    };
    return Honkio().mainShop.userFetch(
        'admincustomertypeset',
        params,
        errorHandler
    );
};

export const createCustomerType = (options, errorHandler) => {
    const opts = {
        ...options,
        action: 'create',
    };
    return setCustomerType(opts, errorHandler);
};

export const updateCustomerType = (options, errorHandler) => {
    const opts = {
        ...options,
        action: 'update',
    };
    return setCustomerType(opts, errorHandler);
};

export const deleteCustomerType = (options, errorHandler) => {
    const opts = {
        ...options,
        action: 'delete',
    };
    return setCustomerType(opts, errorHandler);
};
