import { useMemo, useState, useContext, useEffect } from 'react';
import searchFilterIfConsist from '../UI/helpers/searchFilterIfConsist';
import typifyIfEquals from '../UI/helpers/typifyIfEquals';
import DataTable from 'react-data-table-component';
import InlineSearch from '../UI/InlineSearch';
import Wait from './../UI/Wait';
import UserInfoContext from '../../contexts/UserInfoContext';
import _ from 'lodash';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '../UI/Icon';
import ReactTooltip from 'react-tooltip';

function convertTZ(date, tzString) {
    const tzdate = new Date(date + ' GMT');
    return tzdate.toLocaleString('fi-FI', { timeZone: tzString });
}

const CustomerTypeList = ({
    customerTypes = [],
    emptyText = '',
    loading,
    onDeleteClick = () => {},
    onRowClicked = () => {},
    rowsCountPerPage,
    totalRows,
    handlePageChange,
    handleRowsPerPage,
    perPageChoices = [25],
    handleSort = () => {},
}) => {
    const columns = useMemo(
        () => [
            {
                name: 'Name',
                init_name: 'name',
                grow: 2,
                sortable: true,
                selector: (row) => row.name,
                cell: ({ name }) => name,
            },
            {
                name: 'Description',
                init_name: 'description',
                sortable: true,
                selector: (row) => row.description,
                grow: 2,
                cell: ({ description }) => description,
            },
            {
                name: 'Created',
                init_name: 'created_at',
                grow: 1,
                sortable: true,
                selector: (row) => row.created_at,
                cell: ({ created_at }) =>
                    convertTZ(created_at, 'Europe/Helsinki'),
            },
            {
                name: 'Actions',
                grow: 0,
                right: true,
                cell: ({ _id }) => (
                    <div className="d-flex">
                        <Link to={`/customer/type/edit?id=${_id}`}>
                            <Icon iconName="bi-pen" />
                        </Link>
                        <div
                            onClick={() => {
                                onDeleteClick(_id);
                            }}
                            data-tip
                            data-for="deleteTip"
                        >
                            <Icon
                                iconName="btn bi-trash"
                                color="var(--danger)"
                            />
                            <ReactTooltip
                                id="deleteTip"
                                place="top"
                                effect="solid"
                            >
                                Delete
                            </ReactTooltip>
                        </div>
                    </div>
                ),
            },
        ],
        [loading]
    );

    return (
        <DataTable
            dense
            striped
            noHeader
            highlightOnHover
            progressPending={loading}
            progressComponent={
                <div className="p-5">
                    <Wait />
                </div>
            }
            data={customerTypes}
            columns={columns}
            emptyText={emptyText}
            onRowClicked={onRowClicked}
            pointerOnHover
            pagination={true}
            paginationPerPage={rowsCountPerPage}
            paginationRowsPerPageOptions={perPageChoices}
            paginationServer
            onChangePage={handlePageChange}
            paginationTotalRows={totalRows}
            noDataComponent={emptyText}
            sortServer
            onSort={handleSort}
            onChangeRowsPerPage={handleRowsPerPage}
        />
    );
};

export default CustomerTypeList;
